var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fileContent",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.downloadFile.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "fileContent-wrap" }, [
        _c("div", { staticClass: "fileIcon" }, [
          _c(
            "div",
            {
              staticClass: "loading-style",
              style: { background: _vm.fileInfo.progress < 100 ? _vm.bgC : "" }
            },
            [
              _vm.fileInfo.progress < 100
                ? _c("el-progress", {
                    staticClass: "imelink-message-file__progress",
                    attrs: {
                      type: "circle",
                      percentage: Number(_vm.fileInfo.progress),
                      color: "#fff",
                      "stroke-width": 2,
                      "text-inside": "",
                      width: 40
                    }
                  })
                : _vm._e(),
              _vm.fileInfo.progress >= 100
                ? _c("img", { attrs: { src: _vm.iconSrc } })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm.fileInfo.name
          ? _c("div", { staticClass: "fileNameBox" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.fileInfo.name.length > 20
                      ? `${_vm.fileInfo.name.slice(0, 20)}...`
                      : _vm.fileInfo.name
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm.needDelete
          ? _c(
              "div",
              {
                staticClass: "deleteThisIcon",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("deleteThisFile")
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
            )
          : _vm._e(),
        _vm.fileInfo.progress < 100
          ? _c(
              "div",
              {
                staticClass: "deleteThisIcon cancleStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("cancleThisFile")
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
            )
          : _vm._e()
      ]),
      _vm.byte2M
        ? _c("div", { staticClass: "fileContent-errTips" }, [_vm._v("≤200M")])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }