<template>
  <div class="fileContent" @click.stop="downloadFile">
    <div class="fileContent-wrap">
      <div class="fileIcon">
        <div
          class="loading-style"
          :style="{ background: fileInfo.progress < 100 ? bgC : '' }"
        >
          <el-progress
            v-if="fileInfo.progress < 100"
            type="circle"
            class="imelink-message-file__progress"
            :percentage="Number(fileInfo.progress)"
            color="#fff"
            :stroke-width="2"
            text-inside
            :width="40"
          />
          <img v-if="fileInfo.progress >= 100" :src="iconSrc" />
        </div>
      </div>
      <div class="fileNameBox" v-if="fileInfo.name">
        {{
          fileInfo.name.length > 20
            ? `${fileInfo.name.slice(0, 20)}...`
            : fileInfo.name
        }}
      </div>

      <!-- 删除 -->
      <div
        class="deleteThisIcon"
        v-if="needDelete"
        @click.stop="$emit('deleteThisFile')"
      >
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>

      <!-- 取消上传 -->
      <div
        v-if="fileInfo.progress < 100"
        class="deleteThisIcon cancleStyle"
        @click.stop="$emit('cancleThisFile')"
      >
        <span class="iconfont_Me icon-x iconStyle"></span>
      </div>
    </div>
    <!-- 超过大小提示 -->
    <div class="fileContent-errTips" v-if="byte2M">≤200M</div>
  </div>
</template>
<script>
export default {
  props: {
    // 文件的基本信息
    fileInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否需要展示删除按钮
    needDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  watch: {
    // 监听文件上传进度
    "fileInfo.progress": {
      handler(val) {
        if (val) {
          this.progress = Number(val);
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  computed: {
    byte2M(d) {
      return d / 1024 / 1024 >= 200;
    },
    iconSrc() {
      if (this.judgment(this.fileInfo.name, "txt")) {
        return require("../../../assets/images/newPublish/text.png");
      } else if (
        this.judgment(this.fileInfo.name, "doc") ||
        this.judgment(this.fileInfo.name, "docx")
      ) {
        return require("../../../assets/images/newPublish/word.png");
      } else if (this.judgment(this.fileInfo.name, "pdf")) {
        return require("../../../assets/images/newPublish/pdf.png");
      } else if (
        this.judgment(this.fileInfo.name, "xlsx") ||
        this.judgment(this.fileInfo.name, "xls")
      ) {
        return require("../../../assets/images/newPublish/excel.png");
      } else if (
        this.judgment(this.fileInfo.name, "pptx") ||
        this.judgment(this.fileInfo.name, "ppt")
      ) {
        return require("../../../assets/images/newPublish/ppt.png");
      } else if (
        this.judgment(this.fileInfo.name, "tar") ||
        this.judgment(this.fileInfo.name, "7z") ||
        this.judgment(this.fileInfo.name, "bz2") ||
        this.judgment(this.fileInfo.name, "gz") ||
        this.judgment(this.fileInfo.name, "wim") ||
        this.judgment(this.fileInfo.name, "xz") ||
        this.judgment(this.fileInfo.name, "zip")
      ) {
        return require("../../../assets/images/newPublish/compressed.png");
      } else {
        return require("../../../assets/images/newPublish/unknown.png");
      }
    },
    bgC() {
      if (this.judgment(this.fileInfo.name, "txt")) {
        return "#4cb6fc";
      } else if (
        this.judgment(this.fileInfo.name, "doc") ||
        this.judgment(this.fileInfo.name, "docx")
      ) {
        return "#4c98fc";
      } else if (this.judgment(this.fileInfo.name, "pdf")) {
        return "#fc5a5a";
      } else if (
        this.judgment(this.fileInfo.name, "xlsx") ||
        this.judgment(this.fileInfo.name, "xls")
      ) {
        return "#63c320";
      } else if (
        this.judgment(this.fileInfo.name, "pptx") ||
        this.judgment(this.fileInfo.name, "ppt")
      ) {
        return "#fe9843";
      } else if (
        this.judgment(this.fileInfo.name, "tar") ||
        this.judgment(this.fileInfo.name, "7z") ||
        this.judgment(this.fileInfo.name, "bz2") ||
        this.judgment(this.fileInfo.name, "gz") ||
        this.judgment(this.fileInfo.name, "wim") ||
        this.judgment(this.fileInfo.name, "xz") ||
        this.judgment(this.fileInfo.name, "zip")
      ) {
        return "#83cc39";
      } else {
        return "#b9c9d5";
      }
    },
  },
  methods: {
    // 判断是否是这个文件
    judgment(text, type) {
      const handlerText = text.toLowerCase();
      let typeLength = handlerText.length - handlerText.lastIndexOf(type);
      return handlerText.lastIndexOf(type) != -1 && typeLength == type.length;
    },
    // 下载文件
    downloadFile() {
      let downloadFileUrl = `${this.env(
        "BASEURL"
      )}/rest/user/download?downloadUrl=${this.formartImageUrl(
        this.fileInfo.path
      )}&fileName=${this.fileInfo.name.replace(/<\/?.+?\/?>/g, "")}`;
      window.open(downloadFileUrl, "_blank");
    },
  },
};
</script>
<style lang="stylus" scoped>
.fileContent {
  margin-bottom: 15px;
  // max-width: 642px;
  // width:fit-content;
  border-radius: 8px;
  overflow hidden
  // background: #F7F7F7;
  .fileContent-wrap {
    height: 85px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    padding-left: 25px;
    background: #F7F7F7;
  }

  .fileContent-errTips {
    padding-left: 25px;
    height: 35px;
    line-height 35px;
    font-size: 12px;
    background: #ffede7;
    color: #F84C15;
  }

  .fileIcon {
    width: 52px;
    height: 56px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .loading-style {
      width: 100%;
      height: 100%;
    }

    .imelink-message-file__progress {
      bottom: -9px !important;

      .el-progress-circle {
        background: transparent !important;
        position: relative;
      }

      .el-progress-circle::after {
        font-family: element-icons !important;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        font-size: 25px;
        transform: translate(-50%, -50%);
      }
    }
  }

  .fileNameBox {
    height: 56px;
    flex: 1;
    padding: 0 25px;
    overflow: hidden;
    text-align: left;
    line-height: 56px;
    color: #373737;
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .deleteThisIcon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 2;
    cursor: pointer;

    .iconStyle {
      font-size: 12px;
      color: #ffffff;
    }
  }

  .cancleStyle {
    background: red;
  }
}
</style>
